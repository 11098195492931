@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

*,
.MuiTypography-root,
.MuiButton-root,
.MuiInput-input,
.MuiSelect-select,
/*.MuiTableCell-root,*/
.MuiInputLabel-root {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: 'Play', sans-serif;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}
body {
	padding-top: 100px;
}