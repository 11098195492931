.label_for_context_text {
    max-width: 100%;
    font-weight: 700;
    font-size: 110%;
    color: #333;
    text-align: center;
}

.link_url {
    overflow-wrap: anywhere;
}

p+p {
    margin-top: 0!important;
}

.separate {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
