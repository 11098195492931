.button {
    border-radius: 0;
    background-color: #FFFFFF!important;
    color: #2196F3;
    /*display: inline-block;*/
    /*padding: 0px 12px 19px 1px;*/
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    /*line-height: 1.42857143;*/
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-image: none;
    /*border: 1px solid #2196F3;*/
    margin: 15px 0px 0px 0px;
    display: block;
}

.button:focus,
.button:hover {
    outline: none;
    /*border-color: #8c8c8c;*/
    color: #333;
}

.button:active {
    background-color: #F2F2F2!important;
}

.audio {
    height: 31px;
    width: 130px;
    position: absolute;
    margin-left: 376px;
    margin-top: 12px;
    background: white;
}
