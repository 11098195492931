.profile_label {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

.close_modal {
    background: 0 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    transform: scale(1.4);
}

.close_modal:hover,
.close_modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
}

.wrapper_for_sip_options {
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 0 15px 0 15px!important;
    margin: 10px 15px 10px 15px;
}

.label_for_wrapper_sip {
    max-width: 100%;
    font-weight: 700;
    font-size: 110%;
    color: #333;
    margin-bottom: -10px;
}

.wrapper_for_buttons {
    display: flex;
    justify-content: space-around;
}

.wrapper_for_buttons_key{
    display: flex;
    justify-content: center;
}

.container {
    border: 1px solid #ccc;
    border-radius: 0;
    margin: 15px;
}

.dialog_content {
    padding: 0;
}

.password_input button {
    margin-right: 1px;
    padding: 0;
}

.link_buy {
    display: flex;
    justify-content: center;
}
