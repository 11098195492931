.container {
	margin: 30px auto;
	padding: 0px 10px;
}

.label {
	color: #2196F3;
	font-size: 1.4em;
	font-weight: bold;
	line-height: 2em;
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: center;
}

.input_table {
	border-bottom: none;
}

.MuiTableCell-root {
	border: 1px solid #ddd !important;
	text-align: center;
	vertical-align: middle;
	padding: 8px;
}

.tableCell_select,
.tableCell_input .MuiInputBase-root {
	display: block;
	margin: 10px;
	width: -webkit-fill-available;
	color: rgba(0, 0, 0, 0.87);
	cursor: text;
	display: inline-flex;
	position: relative;
	font-size: 1rem;
	box-sizing: border-box;
	align-items: center;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.1876em;
	letter-spacing: 0.00938em;
}

.MuiInputBase-input {
	font: inherit;
	color: currentColor;
	width: 100%;
	border: 0;
	height: 1.1876em;
	margin: 0;
	display: block;
	padding: 6px 0 7px;
	min-width: 0;
	background: none;
	box-sizing: content-box;
	animation-name: mui-auto-fill-cancel;
	letter-spacing: inherit;
	animation-duration: 10ms;
	-webkit-tap-highlight-color: transparent;
}

.tableCell_select .MuiOutlinedInput-input {
	padding: 2px 0px;
}

.tableCell_select div {
	padding-right: 0 !important;
}

.label-no_records {
	text-align: center;
}

.wrapper-for_datarange {
	text-align: left;
	margin-bottom: 8px;
}

.wrapper-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.wrapper-footer .MuiSelect-select {
	font-size: 15px;
	min-height: 0.5em;
	line-height: normal;
}

.wrapper-footer .MuiTablePagination-toolbar {
	margin-right: 100px;
}

.wrapper-buttons {
	display: flex;
}

.button-export {
	border-radius: 0;
	background: #F2F2F2 !important;
	color: #2196F3;
	display: inline-block;
	margin-left: 10px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
}

.button-export:focus,
.button-export:hover {
	outline: none;
	border-color: #8c8c8c;
	color: #333;
}

.button-export:active {
	background-color: #F2F2F2 !important;
}

thead td {
	position: -webkit-sticky;
	position: sticky;
	top: 27px;
	z-index: 5;
	background: #fff;
}

/*.tableContainer {
    max-height: 70vh;
}*/

.MuiTableCell-root span {
	font-weight: 600;
}

.row_header th,
.row_background {
	background: #F2F2F2 !important;
}

.audioCell {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.getAppIconAudio {
	padding-left: 30px;
	padding-right: -10px;
	cursor: pointer;
	color: #1976d2;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.getAppIconVideo {
	padding-left: 30px;
	padding-right: 10px;
	cursor: pointer;
	color: #1976d2;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}