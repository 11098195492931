* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.button {
	font-family: Inter, sans-serif;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #fff;
	background-color: #2186F0;
	width: 100px;
	height: 30px;
	cursor: pointer;

	position: relative;
	display: inline-block;
	text-decoration: none;
	transition: 0.5s;
	letter-spacing: 1px;
	overflow: hidden;
	margin-right: 50px;
	margin-left: 50px;
	border-radius: 5px;
	padding-top: 4px;
	text-transform: capitalize;
}

.button:hover {
	background: #1077E5;
	/* color: #fff; */
	/* box-shadow: 0 0 5px #2186F0, 0 0 25px #2186F0, 0 0 50px #2186F0, 0 0 200px #2186F0; */
	/* -webkit-box-reflect: below 1px linear-gradient(transparent, #fff); */
}

/* // .button:nth-child(1)
//     filter: hue-rotate(270deg)

// .button:nth-child(2)
//     filter: hue-rotate(110deg) */

/* .button span {
	position: absolute;
	display: block;
}

.button span:nth-child(1) {
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, transparent, #2186F0);
	animation: animate1 1s linear infinite;
}

@keyframes animate1 {
	0% {
		left: -100%
	}

	50%,
	100% {
		left: 100%
	}
}

.button span:nth-child(2) {
	top: -100%;
	right: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(180deg, transparent, #2186F0);
	animation: animate2 1s linear infinite;
	animation-delay: 0.25s;
}

@keyframes animate2 {
	0% {
		top: -100%
	}

	50%,
	100% {
		top: 100%
	}
}

.button span:nth-child(3) {
	bottom: 0;
	right: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(270deg, transparent, #2186F0);
	animation: animate3 1s linear infinite;
	animation-delay: 0.50s;
}

@keyframes animate3 {
	0% {
		right: -100%
	}

	50%,
	100% {
		right: 100%
	}
}

.button span:nth-child(4) {
	bottom: -100%;
	left: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(360deg, transparent, #2186F0);
	animation: animate4 1s linear infinite;
	animation-delay: 0.75s;
}

@keyframes animate4 {
	0% {
		bottom: -100%
	}

	50%,
	100% {
		bottom: 100%
	}
} */