.label_for_context_text {
    max-width: 100%;
    font-weight: 700;
    font-size: 110%;
    color: #333;
}

.dialog_content {
    padding: 0;
    overflow-y: hidden;
}

.voice_input {
    margin-top: 11px;
}

/* .voice_select {
    width: 100%;
    padding-left: 10px;
} */
