.wrapper {
    display: flex
}

.button {
    border-radius: 0;
    background-color: #FFFFFF!important;
    color: #2196F3;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #2196F3;
}

.button:focus,
.button:hover {
    outline: none;
    border-color: #8c8c8c;
    color: #333;
}

.button:active {
    background-color: #F2F2F2!important;
}

.profile_label {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

.close_modal {
    background: 0 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    transform: scale(1.4);
}

.close_modal:hover,
.close_modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5;
}

.container {
    border: 1px solid #ccc;
    border-radius: 0;
    margin: 15px;
}

.label_for_context_text {
    max-width: 100%;
    font-weight: 700;
    font-size: 110%;
    color: #333;
    text-align: center;
}

.wrapper_for_block {
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 0 15px 0 15px!important;
    margin: 10px 15px 10px 15px;
}
.wrapper_for_datarange {
	margin-bottom: 10px;
	margin-top: 10px;
}

.wrapper_for_block_recording {
	margin: 10px 15px 10px 15px;
}

.dialog_content {
    padding: 0;
}

.email_input {
    margin-top: 11px;
}

.dialogActions {
    display: flex;
    justify-content: space-around;
}

.svg_settings{
    transform: scale(1.3);
}

.voice_wrapper{
	padding-bottom: 10px;
}

.play_button{
	margin-left: -15px;
}