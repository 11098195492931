@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.close_balance {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding: 10px 24px;
    border: none;
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    border-radius: 8px;
    width: 101px;
    height: 40px;
    transition: outline 0.5s ease;
	outline: 2px solid transparent;
}

.close_balance:hover {
    border: 1px solid #484A4D;
    cursor: pointer;
}

.close_balance:active {
    background: #F9F9FA;
    border: 1px solid #484A4D;
}

.close_balance:focus {
    background: #FFFFFF;
    outline: 2px solid #333333;
    box-sizing: border-box;
    border-radius: 0;
}

.close_balance:disabled {
    border: 1px solid #E0E1E6;
    opacity: .6;
}
.disabled_button {
    opacity: .6;
    pointer-events: none;
}

