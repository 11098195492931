.appIcon{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appIcon:hover{
    background: #ECEDF0;
}