@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

.header {
	position: fixed;
	top: 0;
	width: 100%;
	border-bottom: 1px solid #EFEFF3;
	background: #fff;
	z-index: 100;
}

.header__services {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 24px;
	margin-right: 24px;
}

.header__centralized_container {
	width: auto;
	height: 64px;
	margin: 0 25px 0 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header__left_side {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header__right_side {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header__logo {
	margin-right: 36px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.header__logo span {
	font-family: 'Play', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
	letter-spacing: -0.01em;
	color: #333333;
	margin-left: 12px;
	margin-right: 10px;
	text-transform: uppercase;
}

.header__logo p {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: -0.006em;
	color: #AAADB3;
	margin-right: 5px;
}

.header__sidebar_menu__list,
.header__sidebar_menu__list_services {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 8px 0;
}

.header__sidebar_menu__list_services {
	border-top: 1px solid #EFEFF3;
	width: 100%;
	padding: 80px 0;
}

.navbar {
	border-top: 1px solid #EFEFF3;
}

.navbar_header {
	width: auto;
	height: 64px;
	margin: 0 24px 0 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.navbar_header__container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.navbar_header__balance {
	margin-right: 0px;
}

.logoutButton {
	color: #333333;
	margin-right: 30px;
	margin-left: 10px;
	text-transform: none;
}

@media screen and (max-device-width: 1000px) {

	.header__logo>span {
		margin-left: 16px;
	}

	.header__centralized_container {
		width: 100%;
	}

}

@media screen and (max-device-width: 926px) and (max-device-height: 540px),
screen and (max-device-width: 540px) {

	.header__left_side nav,
	.header__services {
		display: none;
	}

	.header__logo>span {
		margin-left: 0;
	}

}