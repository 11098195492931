.button {
    border-radius: 0;
    background-color: #FFFFFF!important;
    color: #2196F3;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #2196F3;
    margin-top: 10px;
}

.button:focus,
.button:hover {
    outline: none;
    border-color: #8c8c8c;
    color: #333;
}

.button:active {
    background-color: #F2F2F2!important;
}

.wrapper {
    text-align: center;
}

.typography {
    font-size: 16px;
}
