.player {
    height: 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.player video {
    height: 100%;
}

.button {
    border-radius: 0;
    background-color: #FFFFFF!important;
    color: #2196F3;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #2196F3;
}

.button:focus,
.button:hover {
    outline: none;
    border-color: #8c8c8c;
    color: #333;
}

.button:active {
    background-color: #F2F2F2!important;
}

.table_step_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table_step{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profile_label {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

.close_modal {
    background: 0 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    transform: scale(1.4);
}

.close_modal:hover,
.close_modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5;
}

.playArrowIcon {
    color: #1976d2;
}