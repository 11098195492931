@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');

.links_services_button_wrapper,
.links_services_button {
    height: 100%;
    display: flex;
    align-items: center;
}


/* .links_services_button_wrapper {
    margin-right: 13px;
} */


.links_services_button:hover {
    cursor: pointer;
}

.links_services_button__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
}

.links_services_button__menu_item {
    width: 290px;
    height: 56px !important;
    padding-left: 24px;
    user-select: none;
    font-family: 'Play';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    color: #333333;
}

.links_services_button__menu_item span {
    margin-left: 16px;
}

.links_services_button__menu_item:hover {
    background: #F9F9FA;
    border-radius: 4px;
    margin-left: 10px;
    height: 24px;
    width: 270px;
    cursor: pointer;
    padding-left: 14px;
}

.links_services_button__menu_item:active {
    background: #EBF4FE;
}

.links_services_button__menu_item:hover svg {
    transform: scale(1.1);
}

.links_services_button svg path {
    fill: #333333;
}