.container {
    margin: 30px auto;
    padding: 0px 10px;
}

.label {
    color: #2196F3;
    font-size: 1.4em;
    font-weight: bold;
    line-height: 2em;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
}

.input_table {
    border-bottom: none;
}

.MuiTableCell-root {
    border: 1px solid #ddd !important;
    text-align: center;
    vertical-align: middle;
    padding: 8px;
}

.tableCell_select,
.tableCell_input {
    display: block;
}

.tableCell_select div {
padding-right: 0!important;
}

.label-no_records {
    text-align: center;
}

.wrapper-for_datarange {
    text-align: left;
    margin-bottom: 8px;
}

.wrapper-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapper-footer .MuiTablePagination-toolbar {
    margin-right: 100px;
}

.wrapper-buttons {
    display: flex;
}

.button-export {
    border-radius: 0;
    background: #F2F2F2!important;
    color: #2196F3;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
}

.button-export:focus,
.button-export:hover {
    outline: none;
    border-color: #8c8c8c;
    color: #333;
}

.button-export:active {
    background-color: #F2F2F2!important;
}

thead td {
    position: -webkit-sticky;
    position: sticky;
    top: 27px;
    z-index: 5;
    background: #fff;
}

/*.tableContainer {
    max-height: 70vh;
}*/

.MuiTableCell-root span {
    font-weight: 600;
}

.row_header th,
.row_background {
    background: #F2F2F2!important;
}
