.balance_button {
    font-family: Inter, sans-serif;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.006em;
    color: #fff;
    border: none;
    width: 220px;
    height: 30px;
    background: #2186F0;
    border-radius: 5px;
    transition: outline 0.5s ease;
	outline: 2px solid transparent;
    margin-right: 30px;
}

.balance_button:hover {
    background: #1077E5;
    cursor: pointer;
}

.balance_button:active {
    background: #2186F0;
}

.balance_button:focus-visible{
    background: #2186F0;
    outline: 2px solid #333333;
    box-sizing: border-box;
}

.balance_button:disabled {
    opacity: .6;
}

.balance_button:-moz-suppressed {
    background: #2186F0;
}

.align{
    display: flex;
    align-items: center;
    justify-content: center;
}

.align span{
    padding-left: 10px;
    padding-right: 5px;
}

.align p{
    padding-left: 10px;
}

.align p:first-child{
    padding-right: 10px;
    padding-top: 5px;
}
@media screen and (max-device-width: 926px) and (max-device-height: 428px), screen and (max-device-width: 428px) {

    .balance_button {
        padding: 0;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        position: fixed;
        right: 16px;
        bottom: 16px;
        z-index: 20;
    }

    .balance_button span {
        display: none;
    }

    .button_plus {
        margin: 21px;
    }

}