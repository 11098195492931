@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.activate_balance {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.006em;
    color: #fff;
    padding: 10px 24px;
    border: none;
    background: #2186F0;
    border-radius: 8px;
    width: 144px;
    height: 40px;
    transition: outline 0.5s ease;
    outline: 2px solid transparent;
}

.activate_balance:hover {
    background: #1077E5;
    cursor: pointer;
}

.activate_balance:active {
    background: #2186F0;
}

.activate_balance:focus-visible {
    background: #2186F0;
    outline: 2px solid #333333;
    box-sizing: border-box;
}

.activate_balance:-moz-suppressed {
    background: #2186F0;
}

.activate_balance:disabled {
    opacity: .6;
}

.activate_balance button {
    width: -moz-available;
    width: -webkit-fill-available;
    padding-bottom: 4px;
}

.activate_balance_disabled {
    pointer-events: none;
    cursor: pointer;
    opacity: .6;
}