@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

* {
	vertical-align: baseline;
	font-weight: inherit;
	font-family: 'Play', sans-serif;
	font-style: inherit;
	font-size: 100%;
	border: 0 none;
	outline: 0;
	padding: 0;
	margin: 0;
}

.disable-mui-background .MuiIconButton-root {
	background: none;
}

.change-input-outlined .MuiOutlinedInput-input {
	padding: 2px 0px;
	width: 4rem;
	padding-left: 10px;
}

.change-input-outlined-tab .MuiOutlinedInput-input {
	padding: 2px 0px;
	width: 20rem;
	padding-left: 10px;
}

.MuiOutlinedInput-root .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
	width: auto;
}

.change-input-outlined-datapicker .MuiOutlinedInput-input {
	padding: 16.5px 10px;
	width: 11rem;
}