.player {
	height: 34px;
}

.player audio {
	height: 100%;
}

.button {
	border-radius: 0;
	color: #1976d2;
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	background-image: none;
}

.button:focus,
.button:hover {
	outline: none;
	border-color: #8c8c8c;
	color: #1976d2;
}

.button:active {
	background-color: #F2F2F2 !important;
}