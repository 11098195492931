@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

.hide {
    display: none;
}

.preloader {
    background-image: url(../../images/backGroundKloud.png);
    background-repeat: no-repeat;
    position: absolute;
    top: calc(50% - 75px);
    right: calc(50% - 160px);
    width: 320px;
    height: 150px;
    animation: show 2s ease-in-out 0s;
}

svg.intro {
    max-width: 800px;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.intro .text {
    display: none;
}

.go .text {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
}

.go .text_stroke {
    fill: none;
    stroke: #000;
    stroke-width: 5px;
    stroke-dashoffset: -900;
    stroke-dasharray: 900;
    stroke-linecap: square;
    stroke-linejoin: miter;
    animation: dash 1.5s ease-in-out 0s forwards;
}

@keyframes dash {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes show {

    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}
